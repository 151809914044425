import axios from "@/plugins/axios";

class FacilitiesService {
  getHospital(id: string): Promise<any> {
    return axios.get(`/management/facilities/${id}`);
  }
  getHospitalCourses(id: string): Promise<any> {
    return axios.get(`/management/facilities/${id}/courses`);
  }
  getHospitalCourseDetail(facility_id: string, course_id: string): Promise<any> {
    return axios.get(`/management/facilities/${facility_id}/courses/${course_id}`);
  }
  getHospitalNoticeList(facility_id: string): Promise<any> {
    return axios.get(`/management/facilities/${facility_id}/information`);
  }
  getAnimalTypes(): Promise<any> {
    return axios.get("management/animal_types");
  }
  getCourseDetail(hospital_id: string, course_id: string): Promise<any> {
    return axios.get(`/management/facilities/${hospital_id}/courses/${course_id}`);
  }
}

export default new FacilitiesService();
