import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { CourseInfo, ReservationInfo, PetData, KeeperData, KeeperAnimalData } from "@/@types/reservation";
interface IReservationModule {
  _reservationInfo: ReservationInfo;
  _selectedMyPets: KeeperAnimalData[];
  _selectedConsultation: string;
  _selectedCourse: CourseInfo | null;
  _selectedDate: string | null;
  _selectedNumberOfPets: number;
  _reReservationPetIds: string[];
  _isReservationFlow: boolean;
  _keeper: KeeperData | null;
}

@Module({
  dynamic: true,
  store,
  name: "reservation",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).reservation,
})
class ReservationModule extends VuexModule implements IReservationModule {
  public _reservationInfo: ReservationInfo = {
    lastName: "",
    firstName: "",
    kanaLastName: "",
    kanaFirstName: "",
    email: "",
    phoneNumber: {
      number1: "",
      number2: "",
      number3: "",
    },
    postalCode: {
      number1: "",
      number2: "",
    },
    address: "",
    pets: [],
    symptoms: "",
    memo: "",
  };

  public _selectedMyPets: KeeperAnimalData[] = [];
  public _selectedConsultation = "";
  public _selectedCourse: CourseInfo | null = null;
  public _selectedDate: string | null = null;
  public _selectedNumberOfPets = 1;
  public _isReservationFlow = false;
  public _keeper: KeeperData | null = null;
  public _reReservationPetIds: string[] = [];

  /**
   * 変更
   * @param info
   */
  @Mutation
  public changeReservationInfo(info: ReservationInfo) {
    this._reservationInfo = info;
  }

  /**
   * 変更
   * @param memo
   */
  @Mutation
  public changeReservationMemo(memo: string) {
    this._reservationInfo.memo = memo;
  }

  /**
   * 変更
   * @param pet
   */
  @Mutation
  public changeSelectedMyPets(pets: KeeperAnimalData[]) {
    this._selectedMyPets = pets;
  }

  /**
   * 変更
   * @param consultation
   */
  @Mutation
  public changeSelectedConsultation(consultation: string) {
    this._selectedConsultation = consultation;
  }

  /**
   * 変更
   * @param course
   */
  @Mutation
  public changeSelectedCourse(course: CourseInfo) {
    this._selectedCourse = course;
  }

  /**
   * 変更
   * @param date
   */
  @Mutation
  public changeSelectedDate(date: string | null) {
    this._selectedDate = date;
  }

  /**
   * 変更
   * @param numberOfPets
   */
  @Mutation
  public changeSelectedNumberOfPets(numberOfPets: number) {
    this._selectedNumberOfPets = numberOfPets;
    this._selectedMyPets = [];
  }

  /**
   * 変更
   * @param value
   */
  @Mutation
  public changeIsReservationFlow(value: boolean) {
    this._isReservationFlow = value;
  }

  /**
   * 変更
   * @param ids
   */
  @Mutation
  public changeReReservationPetIds(ids: string[]) {
    this._reReservationPetIds = ids;
  }

  /**
   * 変更
   * @param ids
   */
  @Mutation
  public saveKeeper(keeper: KeeperData) {
    this._keeper = keeper;
  }

  /**
   * 変更
   */
  @Mutation
  public reservationReset() {
    this._reservationInfo = {
      lastName: "",
      firstName: "",
      kanaLastName: "",
      kanaFirstName: "",
      email: "",
      phoneNumber: {
        number1: "",
        number2: "",
        number3: "",
      },
      postalCode: {
        number1: "",
        number2: "",
      },
      address: "",
      pets: [],
      symptoms: "",
      memo: "",
    };
    this._selectedDate = null;
    this._selectedNumberOfPets = 1;
    this._selectedMyPets = [];
    this._selectedConsultation = "";
    this._selectedCourse = null;
    this._isReservationFlow = false;
    this._keeper = null;
  }

  public get reservationInfo() {
    return this._reservationInfo || {};
  }

  public get selectedMyPets() {
    return this._selectedMyPets || [];
  }

  public get selectedConsultation() {
    return this._selectedConsultation || "";
  }

  public get selectedCourse() {
    return this._selectedCourse || null;
  }

  public get selectedDate() {
    return this._selectedDate || null;
  }
  public get selectedNumberOfPets() {
    return this._selectedNumberOfPets;
  }
  public get isReservationFlow() {
    return this._isReservationFlow || "";
  }
  public get reReservationPetIds() {
    return this._reReservationPetIds || [];
  }
  public get keeper() {
    return this._keeper || null;
  }
}
export default getModule(ReservationModule);
